@import '/src/styles/common/variables';

.wl-setting-container {
    box-shadow: 0 rem(6) rem(18) rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    .ant-input-number-handler-wrap {
        display: none;
    }
    .ant-input-number-input-wrap {
        input {
            height: 44px;
        }
    }

    .wl-setting-header-row {
        border-bottom: rem(1) solid $neutral-divider;

        .tenant-setting-header-title {
            @include text-style(500, rem(18), rem(28), $neutral-black);
        }
    }
}

.form-payout-setting {
    .ant-row {
        @include max-size(577px) {
            flex-direction: column;
        }
        .form-item-payout-setting {
            @include max-size(577px) {
                flex: 1;
                max-width: 100%;
            }
        }
    }
}
